<template>
  <div>
    <SpinnerLogo v-if="loading" />

    <CardList :items="cards">
      <template #title="{ item }">{{ item.title }}</template>
      <template #description="{ item }">{{ item.description }}</template>
      <template #body="{ item }">
        <button class="btn btn-primary btn-block" @click="modals[item.id].show = true">Update</button>

        <ConfirmModal lg-size hide-submit :open="modals[item.id].show" :title="item.title" @close="resetModals">
          <div v-if="!modals[item.id].updating">
            <p>Select which assets you would like to update images for:</p>
            <FormGroup id="imageUpdater" v-model="modals[item.id].form" type="select-array-list" search :options="item.options" />

            <button class="btn btn-primary btn-block mb-3" @click="item.action">Update ({{ modals[item.id].form.length }} assets)</button>
          </div>

          <div v-else>
            <div
              v-for="(result, idx) in modals[item.id].data"
              :key="result.id"
              class="d-flex justify-content-between align-items-center py-2"
              :class="{ 'border-bottom': idx !== modals[item.id].data.length - 1 }"
            >
              <div class="font-w600">{{ result.name }}</div>
              <div class="text-right">
                <div v-if="result.status === 'loading'" class="font-w600 text-info"><i class="fa fa-circle-notch fa-spin fa-fw"></i> Loading</div>
                <div v-else-if="result.status === 'success'" class="font-w600 text-success"><i class="fa fa-circle-check fa-fw"></i> Success</div>
                <div v-else-if="result.status === 'failed'" class="font-w600 text-danger"><i class="fa fa-circle-xmark fa-fw"></i> Failed</div>
                <div v-else class="font-w600 text-muted"><i class="fa fa-minus fa-fw"></i> Pending</div>
                <div>
                  <small class="text-muted">{{ result.message }}</small>
                </div>
              </div>
            </div>
          </div>
        </ConfirmModal>
      </template>
    </CardList>
  </div>
</template>

<script>
import bluebird from 'bluebird';
import { mapActions, mapGetters } from 'vuex';

import CardList from '@/components/base/CardList';
import FormGroup from '@/components/FormGroup';
import ConfirmModal from '@/components/ConfirmModal';
import SpinnerLogo from '@/components/SpinnerLogo';

const initialModals = {
  updateImages: () => ({ show: false, data: [], form: [], updating: false }),
  updateGeocoordinates: () => ({ show: false, data: [], form: [], updating: false })
};

export default {
  name: 'AdminToolsAsset',
  components: {
    CardList,
    ConfirmModal,
    FormGroup,
    SpinnerLogo
  },
  data() {
    return {
      loading: false,
      modals: {
        updateImages: initialModals.updateImages(),
        updateGeocoordinates: initialModals.updateGeocoordinates()
      }
    };
  },
  computed: {
    ...mapGetters({
      companies: 'company/globalCompanies',
      loadingAction: 'asset/loadingAction',
      assets: 'asset/assets'
    }),
    cards() {
      const assets = [...this.assets];

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return [
        {
          id: 'updateImages',
          title: 'Update Images',
          description: 'Bulk update satellite and street view images for assets.',
          options: assets.map(a => ({
            value: a.id,
            label: `${a.siteName} (${a.entity?.legalName}) (Parent: ${a.entity?.parentEntity?.legalName})`
          })),
          action: this.onClickUpdateImages
        },
        {
          id: 'updateGeocoordinates',
          title: 'Update Geocoordinates',
          description: 'Bulk fetch lat/long for assets based on their address. This will not update any assets that have their lat/long overidden.',
          options: assets
            .filter(a => a.address.autoLatLng || !a.address.latitude || !a.address.longitude)
            .map(a => ({
              value: a.id,
              label: `${a.siteName} (${a.entity?.legalName}) (Parent: ${a.entity?.parentEntity?.legalName})`
            })),
          action: this.onClickUpdateGeocoordinates
        }
      ];
    }
  },
  async mounted() {
    await this.listCompanies();
    await this.listAssets({
      data: {
        params: {
          $populate: 'entity,entity.parentEntity',
          $select: 'entity.legalName,entity.parentEntityId,entity.parentEntity.legalName',
          $limit: 100000
        }
      }
    });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      listCompanies: 'company/getGlobalCompanies',
      listAssets: 'asset/list',
      updateAsset: 'asset/update',
      updateSatelliteImages: 'asset/updateSatelliteImages',
      getGeocoordinates: 'asset/getGeocoordinates'
    }),
    onClickUpdateGeocoordinates() {
      this.modals.updateGeocoordinates.updating = true;

      this.modals.updateGeocoordinates.data = [...this.modals.updateGeocoordinates.form].map(id => ({
        id: id,
        name: this.assets.find(a => a.id === id).siteName,
        status: 'pending',
        message: ''
      }));

      bluebird.map(
        this.modals.updateGeocoordinates.data,
        async (asset, idx) => {
          this.$set(this.modals.updateGeocoordinates.data[idx], 'status', 'loading');
          this.$set(this.modals.updateGeocoordinates.data[idx], 'message', 'Fetching coordinates...');

          const geoResponse = await this.getGeocoordinates({ id: asset.id });

          if (!geoResponse.lat) {
            this.$set(this.modals.updateGeocoordinates.data[idx], 'status', 'failed');
            this.$set(
              this.modals.updateGeocoordinates.data[idx],
              'message',
              'Bad response when fetching coordinates, check address is correct. ' + JSON.stringify(geoResponse.body || geoResponse)
            );
            console.log(geoResponse);
            return;
          }

          this.$set(this.modals.updateGeocoordinates.data[idx], 'message', 'Updating asset with new coordinates...');

          const assetResponse = await this.updateAsset({
            id: asset.id,
            data: { 'address.latitude': geoResponse.lat, 'address.longitude': geoResponse.lng }
          });

          if (!assetResponse.address.latitude) {
            this.$set(this.modals.updateGeocoordinates.data[idx], 'status', 'failed');
            this.$set(
              this.modals.updateGeocoordinates.data[idx],
              'message',
              'Bad response when updating asset with new coordinates: ' + assetResponse.body?.error?.message
            );
            console.log(assetResponse);
            return;
          }

          this.$set(this.modals.updateGeocoordinates.data[idx], 'status', 'success');
          this.$set(this.modals.updateGeocoordinates.data[idx], 'message', `Lat: ${geoResponse.lat}, Lng: ${geoResponse.lng}`);
        },
        { concurrency: 1 }
      );
    },
    onClickUpdateImages() {
      this.modals.updateImages.updating = true;

      this.modals.updateImages.data = [...this.modals.updateImages.form].map(id => ({
        id: id,
        name: this.assets.find(a => a.id === id).siteName,
        status: 'pending',
        message: ''
      }));

      bluebird.map(
        this.modals.updateImages.data,
        async (asset, idx) => {
          this.$set(this.modals.updateImages.data[idx], 'status', 'loading');

          const response = await this.updateSatelliteImages({ id: asset.id });

          if (response._id && response.images) {
            this.$set(this.modals.updateImages.data[idx], 'status', 'success');
            this.$set(this.modals.updateImages.data[idx], 'message', `Fetched ${response.images.length} images`);
            console.log(response.images);
          } else {
            this.$set(this.modals.updateImages.data[idx], 'status', 'failed');
            this.$set(this.modals.updateImages.data[idx], 'message', response.body?.error?.message);
            console.log(response);
          }
        },
        { concurrency: 1 }
      );
    },
    resetModals() {
      this.modals.updateImages = initialModals.updateImages();
      this.modals.updateGeocoordinates = initialModals.updateGeocoordinates();
    }
  }
};
</script>
