<template>
  <div class="CardList">
    <div v-for="(item, idx) in items" :key="idx" class="CardListItem">
      <div v-if="item.image?.url" class="CardListItem-header" @click.prevent="() => onClick(item)">
        <img :src="item.image.url" alt="" />
        <div class="CardListItem-type font-w600">{{ item.type }}</div>
      </div>
      <div class="CardListItem-body">
        <h4 class="CardListItem-title mb-1" @click.prevent="() => onClick(item)">
          <slot name="title" :item="item">{{ item.title }}</slot>
        </h4>

        <p class="CardListItem-description mb-3 text-muted">
          <slot name="description" :item="item">{{ item.description }}</slot>
        </p>
        <slot name="body" :item="item"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardList',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onClick(item) {
      this.$emit('click');

      if (item.link?.name) {
        this.$router.push(item.link);
      }
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/_scss/custom/variables';

.CardList {
  box-sizing: border-box;
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  justify-content: center;
}

.CardListItem {
  display: flex;
  flex-direction: column;

  &-header {
    position: relative;
    height: 160px;

    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &-title,
  &-header {
    cursor: pointer;
  }

  &-type {
    position: absolute;
    white-space: nowrap;
    top: 0.7rem;
    left: 0.7rem;
    background-color: #fff;
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
  }

  &-body {
    display: flex;
    flex-direction: column;
    height: 200px;

    padding: 0.75rem;

    border-top: 2px solid $gray-lines;
    border-left: 2px solid $gray-lines;
    border-right: 2px solid $gray-lines;
    border-bottom: 2px solid $gray-lines;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &-header + &-body {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &-description {
    position: relative;
    flex-grow: 1;
    overflow-y: hidden;
    font-weight: 500;
    line-height: 1.3rem;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 1em;
      background: linear-gradient(hsla(0, 0%, 100%, 0), #fff);
      z-index: 2;
    }
  }
}
</style>
